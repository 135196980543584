import { render, staticRenderFns } from "./SignedUp.vue?vue&type=template&id=c1104d5e&scoped=true&"
import script from "./SignedUp.vue?vue&type=script&lang=js&"
export * from "./SignedUp.vue?vue&type=script&lang=js&"
import style0 from "./SignedUp.vue?vue&type=style&index=0&id=c1104d5e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c1104d5e",
  null
  
)

export default component.exports