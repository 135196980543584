<template>
  <div class="partners">

    <section>
      <div class="container">

        <h2 class="title">Partnere</h2>
        <p class="subtitle">{{ universe().title }} er baseret på vores partneres ekspertviden om emnet. Her får du et overblik over, hvem der er partnere på podcasten.</p>

        <div class="row">

          <div v-for="partner in universe().partners" :key="partner" class="partner col-12 col-sm-6 col-md-4 col-lg-3">
          <img :src="require('@/assets/images/partners/'+partner.logo)" :alt="partner.title" />
            <p class="title">{{ partner.title }}</p>
            <p class="description">{{ partner.description }}</p>
            <a class="link" target="_blank" :href="partner.link">Læs mere om {{ partner.title }} her</a>
          </div>

        </div>

      </div>
    </section>

    <section class="grey">
      <div class="container">

        <h2 class="title">Vil du være partner?</h2>

        <!-- <p class="filltext">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh rutrum condimentum iaculis fermentum dignissim consectetur fermentum risus, semper. Sit ac amet dui dolor amet pellentesque posuere vitae. Ac sed commodo sagittis dictum urna donec dictum. Sapien ut fusce sit felis ut. Ipsum proin auctor velit consectetur dignissim maecenas.</p> -->

        <p class="information">
          info@altomx.dk
        </p>

      </div>
    </section>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Partners',
  computed: {
    ...mapGetters({
      universes: 'universes'
    })
  },
  methods: {
    universe() {
      return this.universes[this.$route.params.category] ?? false
    }
  }
}
</script>

<style lang="scss" scoped>
.partners {
  section {
    display: block;
    padding: 160px 0px;
    &.grey {
      background: var(--lightest);
    }
    .title {
      display: block;
      font-size: 3.2rem;
      font-weight: 700;
      margin-bottom: 20px;
      word-wrap: break-word;
    }
    .subtitle {
      display: block;
      font-size: 1.2rem;
      margin-bottom: 20px;
    }
    .partner {
      margin-top: 50px;
      img {
        display: block;
        height: auto;
        width: 100%;
        object-fit: contain;
        margin-bottom: 20px;
        border: 1px solid #DDDDDD;
        border-radius: 4px;
      }
      .title {
        font-size: 1.8rem;
        margin-bottom: 10px;
      }
      .description {
        margin-bottom: 10px;
      }
      .link {
        color: var(--darker);
      }
    }
    .filltext {
      font-size: 1.2rem;
      margin: 40px 0px;
      p {
        margin: 0px;
      }
    }
    .information {
      font-size: 1.2rem;
      margin: 40px 0px 0px 0px;
      color: var(--darker);
      font-weight: 600;
      p {
        margin: 0px;
      }
    }
  }
}
</style>
