<template>

  <div class="hero">

      <div class="container">

        <div class="row">

          <div class="col-12 col-md-5 about mb-5 mb-lg-0">

            <p class="date">{{ format(published) }}</p>

            <p class="title">{{ title }}</p>

            <div class="tag" v-for="(tag, index) in tags" :key="index">
              {{ tag }}
            </div>

          </div>

          <div class="col-12 col-md-5 offset-md-2 actions">
            <div class="row">

              <div class="col-12 justify-content-center">

                <div class="play" @click="setPlayerSrc({ src: url, id: id, title: title })">
                  <svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                    <path class="triangle" d="m16,9.938147l0,28l22,-14l-22,-14z" />
                  </svg>
                </div>

                <p class="duration">{{ new Date(duration).toISOString().substr(11, 8) }}</p>

              </div>

              <div class="col-12 description">
                <p>{{ excerpt }}</p>
                <router-link :to="{ name: 'Episode', params: { id: id }}">Læs mere</router-link>
              </div>

            </div>
          </div>

          <div class="icon">
            <component :is="universe().icon"></component>
          </div>

        </div>

      </div>

  </div>

</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Coin from '@/components/svg/Coin'
import Compass from '@/components/svg/Compass'

export default {
  name: 'Hero',
  components: {
    Coin,
    Compass
  },
  props: {
    id: { type: Number, required: true },
    title: { type: String, required: true },
    description: { type: String, required: true },
    duration: { type: Number, required: true },
    published: { type: String, default: '' },
    url: { type: [String, Number], required: true },
    tags: { type: Array, default: () => [] }
  },
  computed: {
    ...mapGetters({
      universes: 'universes'
    }),
    excerpt() {
      return (this.description.length < 260) ? this.description : this.description.substring(0, 256) + '...'
    }
  },
  methods: {
    universe() {
      return this.universes[this.$route.params.category] ?? false
    },
    ...mapMutations([
      'setPlayerSrc'
    ])
  }
}
</script>

<style lang="scss" scoped>
.hero {
  position: relative;
  display: block;
  padding: 120px 0px;
  background: var(--darker);
  color: #FFFFFF;
  overflow: hidden;
  .about {
    z-index: 10;
    .date {
      font-size: 1rem;
    }
    .title {
      font-size: 3rem;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 1;
      word-wrap: break-word;
      @media (max-width: 576px) {
        font-size: 2.2rem;
      }
    }
    .tag {
      border: 1px solid #FFFFFF;
      margin: 10px 10px 0px 0px;
    }
  }
  .actions {
    z-index: 10;
    .play {
      display: inline-block;
      height: 66px;
      width: 66px;
      padding: 16px;
      border-radius: 33px;
      background: #FFFFFF;
      transition: background 0.3s;
      &:hover {
        cursor: pointer;
      }
      svg {
        width: 100%;
        height: 100%;
        .triangle {
          fill: var(--darker);
        }
      }
      @media (max-width: 576px) {
        height: 60px;
        width: 60px;
      }
    }
    .duration {
      display: inline-block;
      margin: 0px 0px 0px 20px;
      font-size: 1.1rem;
      color: #FFFFFF;
    }
    .description {
      margin-top: 30px;
      p {
        margin: 0px;
        white-space: pre-line;
      }
      a {
        display: block;
        margin-top: 15px;
        color: #FFFFFF;
        text-decoration: underline;
      }
    }
  }
  .icon {
    position: absolute;
    bottom: 0;
    left: -5%;
    height: 90%;
    max-height: 420px;
    z-index: 1;
    opacity: 0.6;
  }
}
</style>