<template>
  <nav
    id="nav"
    :class="'navigation ' + ($route.meta.navColored ? 'colored' : '')"
  >
    <div class="main">
      <div class="row">
        <div class="col-3">
          <router-link
            class="logo"
            :to="$route.params.category ? '/' + $route.params.category : '/'"
          >
            <component
              :is="universe().logo"
              :classes="$route.meta.navColored ? 'colored' : ''"
            ></component>
          </router-link>
        </div>

        <div class="col-9 navigation--menu">
          <!-- <ul>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
              >
                <path
                  d="M16 16H12V12H16V16ZM10 16H6V12H10V16ZM4 16H0V12H4V16ZM16 10H12V6H16V10ZM10 10H6V6H10V10ZM4 10H0V6H4V10ZM16 4H12V0H16V4ZM10 4H6V0H10V4ZM4 4H0V0H4V4Z"
                />
              </svg>
            </li>
          </ul> -->

          <ul class="socials">
            <li><p class="tab">Abonnér her:</p></li>
            <li v-if="universe().socials.apple">
              <a class="tab" :href="universe().socials.apple"
                ><img
                  src="@/assets/images/socials/apple.svg"
                  alt="Apple Podcast"
              /></a>
            </li>
            <li v-if="universe().socials.spotify">
              <a class="tab" :href="universe().socials.spotify"
                ><img src="@/assets/images/socials/spotify.svg" alt="Spotify"
              /></a>
            </li>
            <li v-if="universe().socials.android">
              <a class="tab" :href="universe().socials.android"
                ><img
                  src="@/assets/images/socials/android.svg"
                  alt="Android Podcast"
              /></a>
            </li>
            <li v-if="universe().socials.podimo">
              <a class="tab" :href="universe().socials.podimo"
                ><img src="@/assets/images/socials/podimo.svg" alt="Podimo"
              /></a>
            </li>
            <li><div class="divider"></div></li>
          </ul>
          <div class="navigation--menu-link">
            <router-link to="/">
              <span>Flere emner</span>
              <home></home>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
  import { mapGetters } from "vuex";
  import LogoPenge from "@/components/svg/LogoPenge";
  import LogoRejser from "@/components/svg/LogoRejser";
  import Coin from "@/components/svg/Coin";
  import Compass from "@/components/svg/Compass";
  import Home from "@/components/svg/Home";

  export default {
    name: "Navigation",
    components: {
      LogoPenge,
      LogoRejser,
      Coin,
      Compass,
      Home,
    },
    computed: {
      ...mapGetters({
        universes: "universes",
      }),
    },
    methods: {
      universe() {
        return this.universes[this.$route.params.category] ?? false;
      },
    },
  };
</script>

<style scoped lang="scss">
  $nav-height: 110px;

  .navigation {
    background: #ffffff;
    padding: 0 80px;
    @media (max-width: 768px) {
      padding: 0px 20px;
    }

    .main {
      .logo {
        height: $nav-height;
        display: -webkit-box;
        -webkit-box-align: center;
      }
      .navigation--menu {
        display: flex;
        justify-content: flex-end;
      }
      ul {
        text-align: right;
        height: 100%;
        float: right;
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          float: left;
          height: $nav-height;
          margin: 0;
          margin-left: 20px;
          position: relative;
          display: -webkit-box;
          -webkit-box-align: center;
          img,
          svg {
            width: 20px;
            height: auto;
          }
          a,
          p {
            padding: 0;
            margin: 0;
            text-decoration: none;
            color: rgba(0, 0, 0, 0.6);
            vertical-align: top;
            font-size: 0.9rem;
          }

          .divider {
            width: 1px;
            height: 30px;
            background: rgba(0, 0, 0, 0.6);
            margin: 0 10px;
          }
        }
        &.socials {
          @media (max-width: 576px) {
            display: none;
          }
        }
      }
      .navigation--menu-link {
        svg {
          fill: rgba(0, 0, 0, 1);
        }

        span {
          margin: 0 14px 0 20px;
          color: rgba(0, 0, 0, 0.6);

          @media (max-width: 576px) {
            display: none;
          }
        }
      }
    }

    &.colored {
      background: var(--darker);
      .main {
        ul {
          li {
            img {
              filter: brightness(0) invert(1);
            }
            a,
            p {
              color: rgba(255, 255, 255, 0.5);
            }
            .divider {
              background: rgba(255, 255, 255, 0.6);
            }
          }
        }
        .navigation--menu-link {
          svg,
          span {
            color: rgba(255, 255, 255, 0.5);
            fill: rgba(255, 255, 255, 1);
          }
        }
      }
    }
  }
</style>
