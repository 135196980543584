<template>
  <div class="lead">
    <div class="container">
      <div class="row">
        <div class="col-md-8 mx-auto">
          <p class="fs-18 fs-lg-32">
            {{ text }}
          </p>

          <div class="form">
            <mailchimp :redirect="$route.params.category + '/tilmeldt'" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Mailchimp from "@/components/Mailchimp";

  export default {
    name: "Lead",
    props: {
      text: { type: String, default: "" },
    },
    components: {
      Mailchimp,
    },
  };
</script>

<style lang="scss" scoped>
  .lead {
    padding: 160px 0;

    .form {
      margin-top: 60px;
    }
  }
</style>
