<template>
  <div class="privacy-policy">

    <section>
      <div class="container">

        <h2 class="title">Privatlivspolitik</h2>

<p>
Tak for dit besøg på vores hjemmeside. Det er vigtigt for os, at vi beskytter og respekterer dit privatliv i forbindelse med, at du har valgt at bruge vores services.
<br><br>
Vi har høje etiske standarder og har fastsat strenge interne procedurer, der sikrer, at vi behandler dine persondata bedst muligt. I vores privatlivspolitik kan du læse vilkårene for behandling af dine persondata og de rettigheder, du har i forbindelse med brug af vores hjemmeside.
<br><br>
Har du spørgsmål eller kommentarer til vores privatlivspolitik, eller vil du gerne i kontakt med os i øvrigt, er du velkommen til at kontakte os.
<br><br><br>

<b>Hvad er persondata?</b>
<br>
Persondata kan være mange ting. Det kan være navn, adresse og telefonnummer. Det kan også være et billede eller en IP-adresse. Persondata er alle former for information, som kan bruges til at identificere en person. Det er derfor ikke kun den enkelte oplysning, der afgør om noget kan kaldes persondata. Hvis flere persondata tilsammen ikke kan identificere en person, men tilsammen kan, har de karakter af persondata.
<br><br>
Vi indsamler så få persondata som overhovedet muligt:
<br>
<ul>
  <li>Når du handler med os</li>
  <li>Når du tilmelder dig vores nyhedsbrev</li>
  <li>Når du deltager i kampagner, konkurrencer eller undersøgelser</li>
  <li>Når du er i kontakt med vores kundeservice</li>
  <li>Når du selv giver os dine persondata</li>
</ul>
<br>
Vi bruger dine persondata på flere måder. Her kan du læse følgende:
<br>
<ul>
  <li>At vi indsamler og bruger dine persondata til bestemte formål</li>
  <li>At vi sletter dine persondata, når de ikke længere er nødvendige</li>
  <li>At vi løbende kontrollerer og opdaterer dine persondata</li>
  <li>At vi videregiver dine persondata i visse tilfælde</li>
</ul>
<br>
Der er visse persondata, vi er nødt til at kende om dig for at kunne levere vores service til dig. Det er for eksempel dit navn, din adresse, dit telefonnummer og din e-mailadresse – det vil sige nødvendige identifikations- og kontaktoplysninger.
<br><br>
Dette udgør således vores lovlige ’behandlingsgrundlag’. Kan vi ikke behandle disse persondata, kan vi ikke levere vores service til dig.
<br><br>
Det kan også være, at vi har et andet behandlingsgrundlag, f.eks. at vi lovgivningsmæssigt, skal registrere og gemme visse persondata. Det er for eksempel persondata til brug for vores overholdelse af skattelovgivningen og af bogføringsloven.
<br><br>
Ønsker vi at bruge dine persondata på en anden måde end det, vi har indsamlet dem til, oplyser vi dig om det, hvis ’rammen’ for det oprindelige formål overskrides. Det gør vi, før vi går i gang og oplyser dig samtidig begrundelsen herfor.
<br><br>
Vi gør opmærksom på, at vi i medfør af gældende dansk lovgivning har ret til at kontakte dig som kunde med tilbud om vores egne produkter, som er tilsvarende dem, du tidligere har købt hos os. Det gælder, hvis vi har modtaget din e-mailadresse i forbindelse med dit køb og uanset, at du ikke har givet specifikt samtykke hertil. Hvis vi kontakter dig med sådanne tilbud, vil vi klart og tydeligt præsentere dig for muligheden for at frabede dig lignende henvendelser i fremtiden.
<br><br>
Ønsker vi at bruge dine persondata på en anden måde end det, vi indsamlede dem til ud fra dit samtykke, beder vi altid om dit fornyede samtykke, hvis ’rammen’ for det oprindelige formål overskrides. Det gør vi, før vi går i gang og oplyser dig samtidig begrundelsen herfor.
<br><br>
Nogle af dine persondata opbevarer vi for at kunne varetage vores interesser i fremtiden, hvis der måtte være behov for det. Vores behandlingsgrundlag er da ’legitime interesser’, som dette forstås i gældende persondatalovgivning.
<br><br>
Det betyder blandt andet, at vi ud fra en konkret vurdering opbevarer dine persondata i en periode. Tidsperioden og omfanget af de persondata ved denne behandling fastsættes ud fra de kriterier, du kan se i afsnittet ’Vi sletter dine oplysninger, når de ikke længere er nødvendige’.
<br><br><br>

<b>Vi sletter dine persondata, når de ikke længere er nødvendige</b>
<br>
Vi laver en skønsmæssig vurdering for at se, hvornår vi ikke længere har brug for dine persondata. Når vi ikke længere har brug for persondataene i henhold til det formål, som vi indsamlede dem til, sletter vi dem.
<br><br>
Vi lægger bl.a. vægt på:
<br>
<ul>
  <li>Hvilken ydelse, vi har leveret, f.eks. om vi har leveret et produkt eller rådgivning som nævnt i de følgende afsnit</li>
  <li>Hvor længe siden det er, vi havde en relation til dig som medarbejder, kunde, samarbejdspartner eller andet</li>
  <li>Om der har været dialog eller korrespondance siden da</li>
  <li>Om vi ved, at du kontakter os med jævne mellemrum, f.eks. hvert halve år for at bestille nye varer, da vi gerne vil give dig så god service som muligt</li>
  <li>Om du har givet os samtykke til at opbevare persondataene, bl.a. med henblik på senere markedsføringstiltag</li>
  <li>Hvilket ansvar, vi påtager os over for dig, og hvilket ansvar, vi risikerer i relation til vores rådgivning</li>
  <li>Nogle persondata skal vi opbevare i minimum fem år af hensyn til lovgivningen, bl.a. bogføringsloven. Det er for eksempel persondata til brug for udstedelse af fakturaer, så vi kan afregne skat og moms og korrekt kunne dokumentere det over for myndighederne</li>
</ul>
<br>
Da vores ydelse omfatter produkter (f.eks. anlæg hvor vi skal kunne rådgive dig i forbindelse med køb af reservedele), som vi har et ansvar for over dig eller andre, opbevarer vi som udgangspunkt nogle persondata i minimum 10 år. Det er blandt andet dine kontaktpersondata og mailkorrespondance med dig.
<br><br>
Det gør vi for at kunne varetage vores økonomiske interesser og retsstilling, hvis nogen måtte mene, at vi har handlet ansvarspådragende. I så fald skal vi kunne dokumentere, hvilke persondata vi har fået, hvilken aftale, der var indgået med kunden og hvad vi har gjort i forhold til kunden, så vi kan varetage vores interesser. Vi ’renser’ dokumenterne for de persondata, der ikke er nødvendige herfor.
<br>
<ul>
  <li>Vi kontrollerer og opdaterer løbende dine persondata</li>
  <li>Vi kontrollerer løbende, at de persondata, vi behandler om dig, ikke er forkerte eller vildledende</li>
</ul>
<br>
Det gør vi ved med jævne mellemrum at sende dig en e-mail med de persondata, vi har registreret om dig. Du kan benytte kontaktoplysningerne i bunden til at meddele os dine ændringer.
<br><br><br>

<b>Vi videregiver dine persondata i disse tilfælde</b>
<br>
Vi sælger ikke, vi offentliggør ikke og vi viderebringer ikke på anden måde dine persondata til andre, medmindre:
<br>
<ul>
  <li>det er nødvendigt for at vi kan udføre vores service overfor dig, eller</li>
  <li>det er nødvendigt, for at vi overholder lovgivningen, eller</li>
  <li>du har givet os samtykke til det, eller</li>
  <li>det er nødvendigt for at beskytte en samarbejdspartner eller en tredjepart (der er strenge regler i lovgivningen for at skulle videregive persondata på dette grundlag)</li>
  <li>det er som led i vores anvendelse af databehandlere, både i og uden for EU</li>
  <li>Vi samarbejder med udvalgte og betroede partnere om at levere vores service til dig, bl.a. vores egne koncerninterne selskaber, samarbejdspartnere og databehandlere</li>
</ul>
<br>
Til dem viderebringer vi de nødvendige persondata, således at vi samlet set kan levere vores ydelse til dig. Det kan f.eks. være service- og vedligeholdelsesopgaver, konkret rådgivning f.eks. i forbindelse med tilbud, og outsourcing af vores IT-systemer. Det kan også være Det Centrale Personregister, for at vi kan ajourføre eventuelle navne- eller adresseændringer i databaser om vores kunder.
<br><br>
Hvis du har givet samtykke: Vi videregiver personlige persondata til virksomheder, organisationer eller enkeltpersoner uden for vores virksomhed og koncern, hvis vi har dit samtykke.
<br><br>
Dit samtykke og dermed videregivelsen til vores samarbejdspartnere dækker blandt andet over, at vores samarbejdspartnere må kontakte dig med henblik på salg og markedsføring.
<br><br>
Du kan til enhver tid gøre indsigelse mod denne form for videregivelse, og du kan også frabede dig henvendelser i markedsføringsøjemed i CPR-registeret.
<br><br>
Hvis det er påkrævet af lovgivningen, eller det er for at beskytte os selv, en samarbejdspartner eller en tredjepart. Lovgivningen giver i visse tilfælde mulighed for, at vi kan videregive persondata uden dit samtykke. Nogle gange skal vi gøre det. Andre gange kan vi gøre det.
<br><br>
I det omfang, lovgivningen tillader det, kan vi videregive persondata med henblik på enten at beskytte eller håndhæve vores rettigheder. Det samme gælder for rettigheder, som tilhører vores samarbejdspartnere og tredjeparter.
<br><br>
Et eksempel på hvor det kan være relevant, er for eksempel i forbindelse med forebyggelse af bedrageri eller andre strafbare forhold.
<br><br>
Vores anvendelse af databehandlere, både i og uden for EU. Vi indhenter dit samtykke, før vi videregiver dine persondata til samarbejdspartnere i tredjelande, medmindre de pågældende fungerer som vores databehandlere. Et tredjeland kan f.eks. være visse lande i Afrika. USA er ikke et tredjeland på grund af den såkaldte Privacy-Shield aftale mellem USA og EU, hvis virksomheden i USA har tilsluttet sig Privacy Shield-aftalen.
Hvis vi videregiver dine persondata til tredjelande, har vi sikret os, at deres niveau for beskyttelse af persondata passer til de krav, vi har opstillet for os selv i denne politik og de krav, vi er underlagt i forhold til lovgivningen.
<br><br><br>

<b>Du har mange rettigheder</b>
<br>
I dette afsnit kan du læse, at du har en række rettigheder i forbindelse med vores behandling af dine persondata, blandt andet at du har:
<br>
<ul>
  <li>Ret til at få forkerte persondata rettet</li>
  <li>Ret til at få indsigt i dine persondata og få udleveret kopi</li>
  <li>Ret til at få slettet dine persondata</li>
  <li>Ret til at kræve begrænsning</li>
  <li>Ret til at gøre indsigelse imod behandling</li>
  <li>Ret til at tilbagekalde samtykke</li>
  <li>Ret til at kræve oplysning om overførsel til lande og organisationer uden for EU</li>
  <li>Ret til at undgå profilering</li>
  <li>Ret til at klage over vores behandling af dine persondata</li>
  <li>Hvis du vil vide mere, eller udnytte dine rettigheder, beder vi dig kontakte os via kontaktinformationerne i bunden</li>
  <li>Ret til at få forkerte persondata rettet</li>
  <li>Vi kontrollerer, at de persondata, vi behandler om dig, ikke er forkerte eller vildledende. Det gør vi ved med jævne mellemrum at sende dig en e-mail med de persondata, vi har registreret om dig. Du kan benytte kontaktoplysningerne i bunden til at meddele os dine ændringer</li>
  <li>Ret til at få indsigt i dine persondata og få udleveret kopi</li>
  <li>Du har til enhver tid ret til at få indsigt i de persondata, vi har registreret om dig og få udleveret en kopi af persondataene</li>
  <li>Du kan også få oplyst formålene med behandlingen, hvor længe, vi opbevarer dine persondata, om vi foretager automatiske afgørelser (herunder profilering), hvem vi videregiver persondataene til og hvor vi har persondataene fra. Dette gælder dog ikke, hvis du allerede er bekendt med persondataene</li>
</ul>
<br>
Vi gør opmærksom på, at retten til indsigt kan være begrænset af hensyn til beskyttelsen af andre personers persondata og til vores erhvervshemmeligheder.
<br><br><br>

<b>Ret til at få slettet dine persondata</b>
<br>
Du kan til enhver tid kræve at få slettet dine persondata, som vi er i besiddelse af. Hvis ikke vi længere har et formål med at være i besiddelse af persondataene, sletter vi dem hurtigst muligt efter din anmodning.
<br><br><br>

<b>Ret til at kræve begrænsning af behandling</b>
<br>
Du har til enhver tid ret til at anmode os om at begrænse behandlingen af dine persondata.
<br><br><br>

<b>Ret til at gøre indsigelse imod behandling</b>
<br>
Du har til enhver tid ret til at gøre indsigelse imod, at vi behandler dine persondata. Dette omfatter retten til at gøre indsigelse imod, at vi anvender persondataene med henblik på markedsføring. Vi vil hurtigst muligt tage stilling til din indsigelse, hvis du fremsætter en sådan.
<br><br><br>

<b>Ret til at tilbagekalde samtykke</b>
<br>
Du kan til enhver tid tilbagekalde det (eller de) samtykke(r), du har givet os.
<br><br>
Ret til at kræve oplysning om overførsel til lande og organisationer uden for EU
Du har ret til at få oplyst, om vi videresender persondata til et land uden for EU. Vi kan oplyse, at vi videresender persondata til IT-virksomheder, der fungerer som vores databehandlere i USA.
<br><br>
Alle vores databehandlere i USA har tilsluttet sig Privacy Shield-aftalen (læs mere her: https://www.datatilsynet.dk/erhverv/tredjelande/eu-us-privacy-shield/) og har forpligtet sig til at overholde gældende persondatalovgivning. Vi kan derfor videregive persondataene til virksomhederne.
<br><br><br>

<b>Ret til at undgå profilering og at vi træffer automatiske afgørelser</b>
<br>
Du har til enhver tid ret til at undgå, at vi laver profiler af dig og dine persondata eller træffer automatiske afgørelser.
<br><br>
Vi kan oplyse, at vi ikke laver profilering i vores virksomhed eller træffer automatiske afgørelser.
<br><br><br>

<b>Klage</b>
<br>
Vi gør alt, hvad vi kan, for at sikre, at dine persondata behandles betryggende og at dine rettigheder beskyttes optimalt og vi gennemgår regelmæssigt vores procedurer og håndteringen af persondata.
<br><br>
Hvis du mod forventning mener, at vi ikke behandler din henvendelse og dine rettigheder i overensstemmelse med lovgivningen, beder vi dig kontakte os, gerne pr. e-mail med teksten ”klage” i emnefeltet.
<br><br>
Du kan skrive til os på admin@altomx.dk.
<br><br>
Vi vil da ekspedere din henvendelse til en overordnet medarbejder i vores virksomhed, således at eventuelle misforståelser og fejlopfattelser kan blive udredt.
<br><br>
Hvis du fortsat mener, at vi ikke behandler din henvendelse og dine rettigheder i overensstemmelse med lovgivningen, kan du klage til Datatilsynet via: Datatilsynet, Borgergade 28, 1300 København K, telefon: 33 19 32 00, www.datatilsynet.dk.
<br><br><br>

<b>Børn</b>
<br>
Vores virksomhed er målrettet voksne. Vi indsamler ikke bevidst persondata fra og om børn.
<br><br>
Vi er realistiske omkring, at f.eks. børns brug af elektroniske enheder aldrig med 100% sikkerhed kan betyde, at vi ikke modtager persondata om børn.
<br><br>
Vi har forsøgt at indrette vores systemer bedst muligt, så vi ikke kan modtage persondata fra børn og vi sletter straks persondataene, hvis vi bliver opmærksom på, at vi utilsigtet har modtaget persondata om børn.
<br><br>
Hvis du er forælder eller værge og mener, at dit barn har givet persondata til os bevidst eller ubevidst, beder vi dig kontakte os hurtigst muligt via vores kontaktpersondata i slutningen af denne tekst.
<br><br><br>

<b>Hvordan opbevarer vi dine persondata?</b>
<br>
Vi er forpligtet til at beskytte dine persondata. Fordi vi både følger lovgivningen, og fordi vores egne interne, etiske regler kræver, at vi passer godt på dine persondata.
<br><br>
Vi benytter relevante og forsvarlige tekniske og organisatoriske sikkerhedsforanstaltninger for at sikre, at der ikke skabes uautoriseret adgang til de persondata, vi opbevarer. Formålet er at sikre, at persondataene ikke anvendes, ødelægges, ændres offentliggøres eller på anden måde misbruges.
<br><br>
I dette afsnit kan du læse, at:
<br>
<ul>
  <li>Vi har interne regler om informationssikkerhed i forbindelse med persondata</li>
  <li>Vi har implementeret IT-tekniske foranstaltninger</li>
  <li>Brugeradfærd er vigtig for at sikre tilstrækkeligt højt sikkerhedsniveau</li>
  <li>Vi orienterer berørte personer, hvis der opstår risiko for eller et egentligt databrud</li>
  <li>Vi har interne regler om informationssikkerhed, som indeholder retningslinjer og procedurer</li>
</ul>
<br>
Det inkluderer blandt andet, at persondata kun er tilgængelig for den (eller de) medarbejder(e), der har brug for det. Medarbejdere, der har brug for at håndtere persondata, har underskrevet en tavshedserklæring.
<br><br>
Inkluderet i vores regler om informationssikkerhed er også, at vi løbende træner vores medarbejdere i korrekt håndtering af persondata og kontrollerer, at reglerne overholdes af medarbejderne.
<br><br>
IT-teknisk har vi blandt andet implementeret følgende foranstaltninger:
<br>
<ul>
  <li>Installeret antivirus på alle de IT-systemer, der behandler persondata</li>
  <li>Installeret adgangskode på computere med jævnligt krav om fornyelse</li>
  <li>Løbende backup af alle IT-systemer, der behandler persondata</li>
  <li>Installeret systemer til behandling af persondataene, som er i overensstemmelse med branchens krav og retningslinjer</li>
  <li>Begrænsning af adgangen til persondata, så kun de medarbejdere, der har behov for det, har adgang – og kun i det omfang, det er nødvendigt</li>
  <li>Kontrol af medarbejderes faktiske adgang til persondata gennem logning og tilsyn</li>
  <li>Undersøgelse af, om de persondata, vi anvender, kan bruges i anonymiseret eller pseudonymiseret form. Hvis det ikke har negativ indvirkning på vores service og forpligtelser over for dig, vil vi benytte dette</li>
  <li>Indgået databehandleraftaler med leverandører, som behandler persondata på vores vegne, så vi sikrer, at behandlingen sker i overensstemmelse med lovgivningen og vores egne regler og etiske standarder</li>
  <li>Gennemførelse af risikovurdering og dokumentation af alle IT-systemer der behandler persondata. Det gør vi for at sikre, at vi har et oplyst grundlag for sikkerhedsniveauet for behandlingen af persondata</li>
  <li>Risikoen og ansvarsfraskrivelse</li>
  <li>Den største fare for misbrug af persondata skyldes menneskers egen adfærd</li>
</ul>
<br>
Det er både op til den enkelte person at passe godt på sine egne persondata (bl.a. aldrig at oplyse passwords til andre), ligesom det er op til vores virksomhed at tage højde for menneskelig indblanding.
<br><br>
Selvom vi har foretaget ovennævnte tiltag for at begrænse risici ved behandlingen af persondata, kan det ikke udgøre en 100% sikkerhed for, at der ikke sker utilsigtede hændelser.
<br><br>
Vi fraskriver os derfor ethvert tab som følge af utilsigtede hændelser relateret til vores brug og behandling af dine persondata i det omfang, vi kan gøre det i medfør af gældende lovgivning.
<br><br>
Vi kan således ikke gøres ansvarlig for tab af nogen art, som opstår relateret til brug af vores virksomhed, vores produkter og ydelser, vores hjemmeside, systemer, apps og anden software i det omfang, vi kan gøre det i medfør af gældende lovgivning.
<br><br>
Vi anbefaler, at du også selv iværksætter tiltag til sikring af dine persondata.
<br><br>
Det kan du gøre blandt andet ved at lukke din browser efter brug, ved at logge ud af alle konti efter brug, ved at installere antivirus- antimalware og anden software, der kan forbedre sikkerheden på din computer.
<br><br>
Vi anbefaler, at du løbende opdaterer software, de apps du bruger, din computer og mobile devices og aldrig oplyser dit password til andre.
<br><br><br>

<b>Underretning</b>
<br>
Som nævnt har vi foretaget en lang række tiltag for at sikre behandlingen af dine persondata.
<br><br>
Skulle vores IT-systemer og andre sikkerhedsforanstaltninger alligevel blive kompromitteret, vil vi give dig besked uden unødig forsinkelse, hvis kompromitteringen indebærer en høj risiko for dine rettigheder og frihedsrettigheder.
<br><br><br>

<b>Links til andre serviceudbydere</b>
<br>
På vores hjemmeside kan der være links til andre hjemmesider, som ikke tilhører vores firma. Vi er ikke ansvarlige for indholdet på disse hjemmesider og vores persondatapolitik gælder ikke for disse virksomheders hjemmesider.
<br><br><br>

<b>Kontaktoplysninger</b>
<br>
Vores virksomhed er dataansvarlig, og sikrer, at dine persondata behandles i overensstemmelse med lovgivningen:
<br>
altomx.dk
<br>
Münster
<br>
CVR: 38996142
<br><br><br>

<b>Opdatering af denne Persondatapolitik</b>
<br>
Vi opdaterer vores persondatapolitik, når vi vurderer, at der er behov for det. Det kan f.eks. være, når vi udbyder nye services og produkter.
<br><br>
Når vi laver ændringer i persondatapolitikken, vil vi nævne det her.
</p>

      </div>
    </section>

  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy'
}
</script>

<style lang="scss" scoped>
.privacy-policy {
  section {
    display: block;
    padding: 160px 0px;
    .title {
      display: block;
      font-size: 3.2rem;
      font-weight: 700;
      margin-bottom: 60px;
      word-wrap: break-word;
    }
  }
}
</style>
