<template>
  <div class="cookie-policy">

    <section>
      <div class="container">

        <h2 class="title">Cookiepolitik</h2>

        <div id="CookieDeclaration"></div>

      </div>
    </section>

  </div>
</template>

<script>
export default {
  name: 'CookiePolicy',
  mounted() {
    let cookiebotScript = document.createElement('script')
    cookiebotScript.setAttribute('src', 'https://consent.cookiebot.com/51c78051-37fc-4103-b5c4-5bf6321c2134/cd.js')
    document.querySelector('#CookieDeclaration').appendChild(cookiebotScript)
  }
}
</script>

<style lang="scss" scoped>
.cookie-policy {
  section {
    display: block;
    padding: 160px 0px;
    .title {
      display: block;
      font-size: 3.2rem;
      font-weight: 700;
      margin-bottom: 60px;
      word-wrap: break-word;
    }
  }
}
</style>
