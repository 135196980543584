<template>
  <div class="newsletter">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-5 title">
          <p>Gå ikke glip af vigtig viden om {{ $route.params.category }}</p>
        </div>

        <div class="col-12 col-md-5 offset-md-2 description">
          <p>
            Hver søndag sender vi en helt kort mail med ugens episoder + et
            bonuslink med mere viden om {{ $route.params.category }}. Så skriv
            dig op her, hvis du vil lære {{ universe().title }}.
          </p>
        </div>

        <div class="col-12 form">
          <mailchimp
            theme="white"
            :redirect="$route.params.category + '/tilmeldt'"
          />
        </div>

        <div class="icon">
          <component :is="universe().icon"></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import Mailchimp from "@/components/Mailchimp";
  import Coin from "@/components/svg/Coin";
  import Compass from "@/components/svg/Compass";

  export default {
    name: "Newsletter",
    components: {
      Mailchimp,
      Coin,
      Compass,
    },
    computed: {
      ...mapGetters({
        universes: "universes",
      }),
    },
    methods: {
      universe() {
        return this.universes[this.$route.params.category] ?? false;
      },
    },
  };
</script>

<style scoped lang="scss">
  .newsletter {
    position: relative;
    display: block;
    padding: 140px 0px;
    background: var(--medium);
    color: #ffffff;
    overflow: hidden;
    .title {
      z-index: 10;
      p {
        font-size: 3rem;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 1;
        word-wrap: break-word;
        @media (max-width: 576px) {
          font-size: 2.6rem;
        }
      }
    }
    .description {
      z-index: 10;
      p {
        font-size: 1.2rem;
      }
    }
    .form {
      z-index: 10;
      margin-top: 60px;
    }
    .icon {
      position: absolute;
      bottom: 0;
      left: -5%;
      height: 60%;
      z-index: 1;
      opacity: 0.6;
    }
  }
</style>
