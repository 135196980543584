import Vue from "vue";
import Vuex from "vuex";
import { episodes } from "./episodes";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    universes: {
      // Alt Om Penge
      penge: {
        title: "Alt Om Penge",
        logo: "LogoPenge",
        icon: "Coin",
        spreakerID: 4753105,
        mailchimpID: "77ff92a4f9",
        subscribedNotice:
          "Mange tak for din tilmelding! Vi glæder os til at sende dig en masse viden om penge.",
        colors: () => {
          return {
            "--black": "#242438",
            "--grey": "#A2B2CC",
            "--lightest": "#F4F8FF",
            "--lighter": "#D9E4F6",
            "--medium": "#7EADFC",
            "--darker": "#083AB7",
            "--darkest": "#0836A3",
          };
        },
        socials: {
          apple:
            "https://podcasts.apple.com/dk/podcast/alt-om-penge/id1558132302",
          spotify: "https://open.spotify.com/show/2mt2GUt0QYT3RvZFlHOgzS",
          android:
            "http://www.subscribeonandroid.com/www.spreaker.com/show/4753105/episodes/feed",
          podimo:
            "https://share.podimo.com/podcast/6ef2dc08-d38e-49f5-838f-a22690d9e92c?creatorId=84b9ea71-7c3c-4874-93f8-556edf00a152&key=7BD0qZFrvGvp&source=ln&from=mobile",
        },
        partners: {
          mybanker: {
            logo: "mybanker.png",
            title: "Mybanker",
            description:
              "Mybanker er en selvejet fintech-virksomhed, som arbejder på at bygge bro mellem borgere, samfund og den finansielle sektor. Mybanker tilbyder bl.a. Skift bank-service, som bruges af 35.000-40.000 husstande årligt.",
            link: "https://www.mybanker.dk",
          },
          pengepugeren: {
            logo: "pengepugeren.png",
            title: "Pengepugeren",
            description:
              "Bag Pengepugeren står far, blogger og podcaster, Rasmus Firla-Holme. Pengepugerens fokus er penge, investering og budgetlægning der fungerer. Målet er at bidrage til, at så mange som muligt kan opnå økonomisk frihed ved selv at tage kontrollen over privatøkonomien.",
            link: "https://pengepugeren.dk/",
          },
          frinans: {
            logo: "frinans.png",
            title: "Frinans",
            description:
              "Jeg hedder Sune, men på nettet er jeg bedre kendt som Frinans. Igennem mit alter ego skriver jeg om og filmer min jagt på økonomisk uafhængighed, mere frihed og flugten fra hamsterhjulet igennem et fokus på rigdomsformlen og simpel investering.",
            link: "https://www.frinans.dk/",
          },
          spiir: {
            logo: "spiir.png",
            title: "Spiir",
            description:
              "Spiir er en app, der giver dig automatisk overblik over din økonomi og hjælper dig med at spare penge på en nem og sjov måde.",
            link: "https://www.spiir.dk/",
          },
          nord: {
            logo: "nord-investments.png",
            title: "NORD.investments",
            description:
              "NORD.investments er en digital investeringsløsning med lave omkostninger til dig der vil investere pensions-, frie-, og virksomhedsmidler. Besøg vores hjemmeside og se hvilken riskoprofil der passer til dig ved at tage en gratis investeringsplan.",
            link: "https://www.nord.investments/",
          },
          dinero: {
            logo: "dinero.png",
            title: "Dinero",
            description:
              "Dinero er et regnskabsprogram til små og mellemstore virksomheder, der vil spare tid og bøvl.",
            link: "https://dinero.dk/",
          },
          miranova: {
            logo: "miranova.png",
            title: "Miranova",
            description:
              "Miranova tilbyder uvildig formuepleje, der er 100 procent uafhængig af provisioner – gennemskuelige investeringer der er baseret på fornuft og fakta og til lave omkostninger, da det øger dine chancer for et højere afkast.",
            link: "https://www.miranova.dk/",
          },
          tobi: {
            logo: "tobi.png",
            title: "Tobi",
            description:
              "Tobi hjælper børnefamilier med at investere deres børneopsparinger. Lidt ligesom pension – bare til børn. Det hele foregår digitalt så du kan let komme i gang når det passer dig.",
            link: "https://www.tobi.dk/",
          },
          oekonomista: {
            logo: "oekonomista.png",
            title: "Økonomista",
            description:
              "Økonomista er et økonomi-magasin, som udkommer to gange om året. Magasinet inspirerer dig til at få en stærkere økonomi med konkrete tips og viden om penge.",
            link: "https://www.oekonomista.dk/",
          },
          financer: {
            logo: "financer.png",
            title: "Financer",
            description:
              "Hos Financer er vores kerneprodukt sammenligning af forskellige økonomiske tjenester, fx handelsplatforme, kreditkort, lån og opsparingskonti. Derudover anmelder vi finansielle apps og tjenester, og skriver guider om privatøkonomi.",
            link: "https://financer.com/dk/",
          },
        },
        meta: {
          title:
            "Alt Om Penge – Podcasten, der lærer dig at være smart med dine penge",
          description:
            "I podcasten lærer du, hvordan du bliver økonomisk uafhængig, hvordan du sparer penge på budgettet, hvordan du investerer smartest og meget, meget mere. Kort sagt, du lærer Alt Om Penge.",
          image: "https://www.altomx.dk/OG-alt-om-penge.png",
        },
      },

      // Alt Om Rejser
      rejser: {
        title: "Alt Om Rejser",
        logo: "LogoRejser",
        icon: "Compass",
        spreakerID: 4930036,
        mailchimpID: "7143f9964a",
        subscribedNotice:
          "Mange tak for din tilmelding! Vi glæder os til at sende dig en masse inspiration til dine rejser.",
        colors: () => {
          return {
            "--black": "#242438",
            "--grey": "#A2B2CC",
            "--lightest": "#F4F8FF",
            "--lighter": "#D9E4F6",
            "--medium": "#00BF60",
            "--darker": "#006D37",
            "--darkest": "#005E2F",
          };
        },
        socials: {
          apple:
            "https://podcasts.apple.com/us/podcast/alt-om-rejser/id1569804359?uo=4",
          spotify:
            "https://open.spotify.com/show/7dCnTsBxv4k5UOPYuHazpa?si=nGmnfpz7Tm-91jgOcosgtA",
          android:
            "https://podcasts.google.com/feed/aHR0cHM6Ly93d3cuc3ByZWFrZXIuY29tL3Nob3cvNDkzMDAzNi9lcGlzb2Rlcy9mZWVk",
          podimo:
            "https://share.podimo.com/podcast/bfb1a1de-b200-41d1-adcc-04f033d10f7a?creatorId=84b9ea71-7c3c-4874-93f8-556edf00a152&key=MauGEFscgXCP&source=ln&from=mobile",
        },
        partners: {
          vagabond: {
            logo: "vagabond.png",
            title: "Vagabond",
            description:
              "Vagabond har udgivet rejsemagasiner siden 1994. Vi benytter de bedste journalister og nogle af verdens førende fotografer, som skriver artikler fra nær og fjern og om alle former for rejser. Vagabond står også bag Rejsemessen for Kvalitetsrejser og Rejsemessen for Aktiv Ferie.",
            link: "https://vagabond.info",
          },
          nordombord: {
            logo: "nordombord.png",
            title: "Nordombord",
            description:
              "Bag rejsebloggen Nordombord står Alexander og Victoria, der siden 2017 har levet som digitale nomader. Nordombord er til for at inspirere og guide danske rejsende til at opdage, udforske og nyde vores vidunderlige verden på en ansvarlig og bæredygtig måde.",
            link: "https://nordombord.dk",
          },
          rejsrejsrejs: {
            logo: "rejsrejsrejs.png",
            title: "RejsRejsRejs",
            description:
              "RejsRejsRejs.dk er Danmarks største online rejsemagasin – og helt gratis. Vi guider dig igennem rejsejunglen og deler vores bedste tips, så du kan få endnu mere ud af dine rejser. Vi har et aktivt rejsefællesskab, hvor der også er plads til dig. Kig ind i dag.",
            link: "https://rejsrejsrejs.dk",
          },
          travelmore: {
            logo: "travelmore.png",
            title: "Travelmore",
            description:
              "Travelmore er et rejsemedie, der udgiver rejseartikler, rejseinfo- og tips, der inspirerer dig til din næste rejseoplevelse. Travelmore udsender et ugentligt nyhedsbrev med sæsonaktuelle artikler og skarpe rejsetilbud fra flere samarbejdspartnere. Travelmore har flere skribenter tilknyttet, som følger skarp med i, hvilke artikler der hitter blandt læserne. Det gør de for at kunne målrette nye artikler og levere indhold, som interesserer læserne.",
            link: "https://travelmore.dk",
          },
          spainbyhanne: {
            logo: "spainbyhanne.png",
            title: "Spain by Hanne",
            description:
              "Spain by Hanne er et rejsesite, hvor du finder inspiration og tips til rejser i Spanien. Sitet drives af rejsejournalist Hanne Olsen, der har Spanien som speciale. På Spain by Hanne finder du tips til nogle af landets vigtigste attraktioner, men formålet med sitet er især at inspirere dig til at søge væk fra de traditionelle turistruter og komme ud i sidegader og afkroge. Du kan også læse om spansk kultur, design- og madtrends. Og så kan du møde nogle af de lokale.",
            link: "https://spainbyhanne.dk",
          },
        },
        meta: {
          title:
            "Alt Om Rejser — Rejseguides, insider-tips og inspiration til de rejseglade",
          description:
            "I podcasten får du guides til de mest spændende destinationer, insider-tips til oversete perler og tips og tricks til at rejse billigere og bedre.",
          image: "https://www.altomx.dk/OG-alt-om-rejser.png",
        },
      },
    },
    player: {
      src: "",
      id: "",
      title: "",
      thumbnail: "",
    },
  },
  getters: {
    player: (state) => state.player,
    universes: (state) => state.universes,
  },
  mutations: {
    setPlayerSrc(state, { src, id, title }) {
      state.player.src = "";
      state.player.src = src;
      state.player.id = id;
      state.player.title = title;
    },
  },
  modules: {
    episodes,
  },
});
