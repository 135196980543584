<template>
  <div class="mailchimp">

    <mailchimp-subscribe
      url="https://altomx.us16.list-manage.com/subscribe/post-json"
      user-id="4145c3869f8f778e604876c9f"
      :list-id="universe().mailchimpID"
      @error="onError"
      @success="onSuccess"
    >
      <template v-slot="{ subscribe, setEmail, loading, error }">
        <form @submit.prevent="subscribe" :class="theme">
          <input type="email" placeholder="Skriv din e-mail her..." @input="setEmail($event.target.value)" />
          <input v-if="!loading" type="submit" value="Hold mig opdateret" />
          <svg class="loading" v-if="loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
            <circle cx="50" cy="50" fill="none" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
              <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
            </circle>
          </svg>
          <div class="error" v-if="error">{{ error.includes('already subscribed') ? 'Denne email er allerede tilmeldt' : 'Hovsa, der skete en fejl' }}</div>
        </form>
      </template>
    </mailchimp-subscribe>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MailchimpSubscribe from 'vue-mailchimp-subscribe'

export default {
  name: 'Mailchimp',
  components: {
    MailchimpSubscribe,
  },
  props: {
    theme: { type: String, default: 'blue' },
    redirect: { type: String, default: '' }
  },
  computed: {
    ...mapGetters({
      universes: 'universes'
    })
  },
  methods: {
    universe() {
      return this.universes[this.$route.params.category] ?? false
    },
    onSuccess() {
      if (this.redirect) {
        this.$router.push(this.redirect)
      } else {
        this.$router.go()
      }
    },
    onError() {}
  },
}
</script>

<style scoped lang="scss">
.mailchimp {
  .error {
    font-size: 1rem;
    position: absolute;
    left: 0px;
    bottom: -2.4rem;
    color: #CC0000;
  }
  .loading {
    margin: auto; 
    background: none; 
    display: block; 
    shape-rendering: auto;
    height: 3.5rem;
    padding: 0.8rem;
    width: auto;
    float: right;
    margin-right: 1rem;
    circle {
      stroke: #FFFFFF;
    }
    @media (max-width: 576px) {
      float: left;
      position: absolute;
      padding: 1rem;
      right: -0.8rem;
    }
  }
  form {
    display: block;
    position: relative;
    font-size: 0px;
    background: var(--darker);
    padding: 20px 10px 20px 30px;
    @media (max-width: 576px) {
      padding: 0px 1.2rem;
    }
    input {
      font-size: 1rem;
      padding: 1rem;
      outline: none;
      border: none;
      background: var(--darker);
      color: #FFFFFF;
      box-sizing: border-box;
      @media (max-width: 576px) {
        font-size: 1rem;
      }
      &::placeholder {
        color: #FFFFFF;
        opacity: 0.6;
      }
      &[type="email"] {
        width: calc(100% - 220px);
        @media (max-width: 576px) {
          width: 100%;
          text-align: center;
          padding: 1.4rem 1rem;
        }
      }
      &[type="submit"] {
        width: 220px;
        padding-left: 1.2rem;
        border-left: 1px solid #FFFFFF;
        border-radius: 0px;
        font-weight: 600;
        @media (max-width: 576px) {
          border-left: none;
          border-top: 1px solid #FFFFFF;
          border-radius: 0px;
          padding: 1.4rem 1rem;
          width: 100%;
        }
      }
    }
  }
  form.white {
    background: #FFFFFF;
    input {
      background: #FFFFFF;
      color: var(--darker);
      &::placeholder {
        color:var(--darker);
      }
      &[type="submit"] {
        border-left: 1px solid var(--darker);
        @media (max-width: 576px) {
          border-left: none;
          border-top: 1px solid var(--darker);
        }
      }
    }
    .loading {
      circle {
        stroke: var(--darker);
      }
    }
  }
}
</style>
