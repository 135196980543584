import Vue from 'vue'
import VueRouter from 'vue-router'

import Index from '@/views/Index.vue'
import SignedUp from '@/views/SignedUp.vue'
import Home from '@/views/Home.vue'
import Episode from '@/views/episodes/Show.vue'
import PrivacyPolicy from '@/views/PrivacyPolicy.vue'
import CookiePolicy from '@/views/CookiePolicy.vue'
import Partners from '@/views/Partners.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
    meta: {
      simpleView: true
    }
  },
  // {
  //   path: '/:category',
  //   name: 'Universe',
  //   component: Universe,
  //   children: [
  //     {
  //       path: '/tilmeldt',
  //       name: 'SignedUp',
  //       component: SignedUp,
  //       meta: {
  //         navColored: true
  //       }
  //     },
  //   ]
  // },


  {
    path: '/:category',
    name: 'Home',
    component: Home,
    meta: {
      navColored: true
    }
  },
  {
    path: '/:category/tilmeldt',
    name: 'SignedUp',
    component: SignedUp,
    meta: {
      navColored: true
    }
  },
  {
    path: '/:category/partnere',
    name: 'Partners',
    component: Partners
  },
  {
    path: '/:category/privatlivspolitik',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/:category/cookiepolitik',
    name: 'CookiePolicy',
    component: CookiePolicy
  },
  {
    path: '/:category/:id',
    name: 'Episode',
    component: Episode
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router