<template>
  <div class="episodes">
    <div class="container">

      <p class="title">{{ title }}</p>

      <template v-if="filters && tags">
        <div v-if="filters && tags" class="select-wrapper">
          <select v-model="filter">
            <option value="">Filtrér tags</option>
            <option v-for="(tag, index) in tags" :key="index" :value="tag">
              {{ tag }}
            </option>
          </select>
        </div>
        <span v-if="filter" class="reset-filter" @click="filter = ''">Nulstil</span>
      </template>

      <div class="row">

        <episode-item v-for="episode in paginatedEpisodes" :key="episode.episode_id"
          :id="episode.episode_id"
          :title="episode.title"
          :duration="episode.duration"
          :url="episode.playback_url"
          :description="episode.description"
          :published="episode.published_at"
          :tags="episode.tags"
          :clickable-tags="Boolean(filters && tags)"
          @tag-click="filter = $event"
          class="col-md-4"
        />

      </div>

      <div class="row" v-if="button && (displaying < filteredEpisodes.length)">
        <div class="col-12 text-center text-md-right">
           <p class="show-all" @click="displaying += limit">Indlæs flere</p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EpisodeItem from '@/components/EpisodeItem'

export default {
  name: 'Episodes',
  props: {
    title: { type: String, default: 'Episoder' },
    episodes: { type: Array, required: true },
    limit: { type: Number, default: 3 },
    button: { type: Boolean, default: true },
    filters: { type: Boolean, default: true }
  },
  components: {
    EpisodeItem
  },
  computed: {
    ...mapGetters('episodes', [
      'tags'
    ]),
    filteredEpisodes() {
      if(!this.filter) { return this.episodes }
      return this.episodes.filter(episode => episode.tags.includes(this.filter))
    },
    paginatedEpisodes() {
      return this.filteredEpisodes.slice(0, this.displaying)
    },
  },
  data() {
    return {
      displaying: this.limit,
      filter: ''
    };
  }
}
</script>

<style lang="scss" scoped>
.episodes {
  padding: 160px 0;
  background: var(--lightest);
  .title {
    display: block;
    font-size: 3.2rem;
    font-weight: 700;
    margin-bottom: 50px;
    word-wrap: break-word;
  }
  .select-wrapper {
    select {
      border: 1px solid var(--lighter);
    }
  }
  .reset-filter {
    color: var(--darker);
    transition: color 0.3s, background 0.3s;
    text-decoration: underline;
    margin-left: 1.5rem;
    cursor: pointer;
  }
  .show-all {
    display: inline-block;
    background: transparent;
    color: var(--darker);
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0px;
    padding: 1rem 4rem;
    border: 1px solid var(--darker);
    transition: color 0.3s, background 0.3s;
    &:hover {
      cursor: pointer;
      background: var(--darker);
      color: #FFFFFF;
    }
  }
  .select-wrapper {
    min-width: 283px;
    margin: -20px 0px 40px 0px;
  }
}
</style>