<template>
  <svg :class="classes" width="191" height="68" viewBox="0 0 191 68" xmlns="http://www.w3.org/2000/svg">
    <path class="text" d="M19.7258 40.353H22.9331V22.2598H19.7258V40.353Z" />
    <path class="text" fill-rule="evenodd" clip-rule="evenodd" d="M13.7493 40.353H17.4035L10.6209 23.4958H6.78265L0 40.353H3.4439L4.91611 36.5661H12.3034L13.7493 40.353ZM11.173 33.6469H6.02026L8.59661 26.9672L11.173 33.6469Z" />
    <path class="text" d="M27.5771 30.5963H25.3688V27.7561H27.6034V24.4951H30.8107V27.7561H34.2283V30.5963H30.837V35.9349C30.837 37.1183 31.3365 37.6969 32.2829 37.6969C32.8613 37.6969 33.4396 37.4339 33.7551 37.0657L35.201 39.4589C34.4912 40.1426 33.3082 40.7212 31.7571 40.7212C29.207 40.7212 27.5771 39.117 27.5771 36.0401V30.5963Z" />
    <path class="text" fill-rule="evenodd" clip-rule="evenodd" d="M48.8702 40.7212C52.5244 40.7212 55.4426 37.7232 55.4426 34.0677C55.4426 30.386 52.5244 27.3879 48.8702 27.3879C45.216 27.3879 42.3242 30.386 42.3242 34.0677C42.3242 37.7232 45.216 40.7212 48.8702 40.7212ZM48.8965 37.6969C46.9511 37.6969 45.5315 36.0664 45.5315 34.0414C45.5315 32.0164 46.9511 30.4122 48.8965 30.4122C50.8156 30.4122 52.209 32.0164 52.209 34.0414C52.209 36.0664 50.8156 37.6969 48.8965 37.6969Z" />
    <path class="text" d="M64.0687 30.4122C62.5177 30.4122 61.5713 31.4905 61.5713 33.1999V40.353H58.364V27.7561H61.5713V29.0973C62.3337 28.0454 63.543 27.3879 65.0415 27.3879C66.8817 27.3879 68.3013 28.2295 69.0637 29.7022C69.7998 28.4662 71.088 27.3879 73.086 27.3879C76.0041 27.3879 77.9232 29.3603 77.9232 32.7265V40.353H74.7159V33.1999C74.7159 31.4905 73.7432 30.4122 72.2447 30.4122C70.6937 30.4122 69.7473 31.4905 69.7473 33.1999V40.353H66.5399V33.1999C66.5399 31.4905 65.5672 30.4122 64.0687 30.4122Z" />
    <path class="text" d="M143.101 22.2861C142.076 22.2861 141.235 23.1539 141.235 24.1796C141.235 25.2315 142.076 26.0468 143.101 26.0468C144.153 26.0468 144.994 25.2315 144.994 24.1796C144.994 23.1539 144.153 22.2861 143.101 22.2861Z" />
    <path class="text" d="M144.731 27.7561H141.524V40.9053C141.524 42.2465 140.893 42.9303 139.999 42.9303C139.368 42.9303 138.895 42.6936 138.501 42.378L137.002 44.7449C137.686 45.4023 138.79 45.8757 140.157 45.8757C143.128 45.8757 144.731 43.8507 144.731 40.7212V27.7561Z" />
    <path class="text" fill-rule="evenodd" clip-rule="evenodd" d="M114.93 40.353H111.643V23.4958H117.637C121.607 23.4958 124.236 25.5997 124.236 28.8081C124.236 31.1223 122.895 32.8317 120.687 33.647L124.604 40.353H120.792L117.348 34.1466H114.93V40.353ZM120.766 28.8081C120.766 27.3617 119.635 26.3886 117.9 26.3886H114.93V31.2538H117.9C119.635 31.2538 120.766 30.2808 120.766 28.8081Z" />
    <path class="text" fill-rule="evenodd" clip-rule="evenodd" d="M137.694 38.7488C136.748 39.8534 134.96 40.7212 132.831 40.7212C128.914 40.7212 126.075 37.7232 126.075 34.0677C126.075 30.386 128.809 27.388 132.437 27.388C136.038 27.388 138.772 30.1756 138.772 33.8048C138.772 34.1992 138.746 34.6463 138.693 34.8567H129.361C129.676 36.6187 131.017 37.881 132.962 37.881C134.224 37.881 135.302 37.3287 136.038 36.5661L137.694 38.7488ZM135.486 32.8317C135.118 31.0434 133.988 30.123 132.489 30.123C130.885 30.123 129.781 31.2275 129.413 32.8317H135.486Z" />
    <path class="text" d="M152.714 29.9652C151.61 29.9652 150.952 30.4649 150.952 31.1486C150.952 32.0467 152.073 32.3667 153.404 32.7465C155.346 33.3009 157.735 33.9828 157.735 36.7765C157.735 38.9066 155.921 40.7212 152.504 40.7212C150.111 40.7212 148.297 39.8534 147.219 38.5911L149.007 36.5398C149.98 37.618 151.189 38.144 152.688 38.144C153.923 38.144 154.554 37.618 154.554 36.8291C154.554 35.8923 153.395 35.533 152.037 35.1121C150.116 34.5168 147.798 33.7983 147.798 31.1486C147.798 29.2289 149.428 27.388 152.635 27.388C154.896 27.388 156.684 28.1506 157.63 29.4655L155.816 31.359C154.975 30.3597 153.923 29.9652 152.714 29.9652Z" />
    <path class="text" fill-rule="evenodd" clip-rule="evenodd" d="M166.488 40.7212C168.618 40.7212 170.406 39.8534 171.352 38.7488L169.696 36.5661C168.96 37.3287 167.882 37.881 166.62 37.881C164.674 37.881 163.334 36.6187 163.018 34.8567H172.351C172.404 34.6463 172.43 34.1992 172.43 33.8048C172.43 30.1756 169.696 27.388 166.094 27.388C162.466 27.388 159.732 30.386 159.732 34.0677C159.732 37.7232 162.571 40.7212 166.488 40.7212ZM166.147 30.123C167.645 30.123 168.776 31.0434 169.144 32.8317H163.071C163.439 31.2275 164.543 30.123 166.147 30.123Z" />
    <path class="text" d="M180.492 30.5175C179.204 30.5175 178.389 31.5957 178.389 33.3051V40.353H175.181V27.7561H178.389V29.1237C179.098 28.0717 180.15 27.388 181.465 27.388C182.306 27.388 182.989 27.6247 183.489 28.0191L182.148 31.0697C181.727 30.7279 181.096 30.5175 180.492 30.5175Z" />
    <path class="icon" fill-rule="evenodd" clip-rule="evenodd" d="M80.7007 3.75691C87.4758 3.75691 92.9691 9.25296 92.9691 16.0339V43.0351L98.6462 47.0116L92.9691 50.9882V51.9661C92.9691 58.747 87.4758 64.2431 80.7007 64.2431V68C89.2157 68 96.1786 61.3547 96.6941 52.9654L105.194 47.0116L96.7247 41.0794V16.0339C96.7247 7.1792 89.5511 0 80.7007 0V3.75691Z" />
  </svg>
</template>

<script>
export default {
  name: 'LogoRejser',
  props: {
    classes: { type: String, default: '' }
  },
}
</script>

<style scoped lang="scss">
svg {
  height: 50px;
  width: auto;
  .text {
    fill: #000000;
  }
  .icon {
    fill: var(--darker);
  }
  &.colored {
    .text {
      fill: #FFFFFF;
    }
    .icon {
      fill: var(--medium);
    }
  }
}
</style>