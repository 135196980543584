<template>
  <div class="home">
    <hero
      v-if="episodes.length > 0"
      :id="episodes[0].episode_id"
      :title="episodes[0].title"
      :description="episodes[0].description"
      :duration="episodes[0].duration"
      :published="episodes[0].published_at"
      :url="episodes[0].playback_url"
      :tags="episodes[0].tags"
    />

    <lead
      :text="
        'Hver søndag sender vi en helt kort mail ud med ugens tre episoder + bonuslinks med mere viden og inspiration til dine ' +
          $route.params.category +
          '. Så hvis du gerne vil lære ' +
          universe().title +
          ' og ikke vil gå glip af noget, så skriv dig op her.'
      "
    />

    <div v-if="episodes.length > 0">
      <episodes :episodes="episodes" :limit="6" />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import Hero from "@/components/Hero";
  import Lead from "@/components/Lead";
  import Episodes from "@/components/Episodes";

  export default {
    name: "Home",
    components: {
      Hero,
      Lead,
      Episodes,
    },
    computed: {
      ...mapGetters({
        universes: "universes",
        episodes: "episodes/all",
      }),
    },
    methods: {
      universe() {
        return this.universes[this.$route.params.category] ?? false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .home {
    section {
      display: block;
      padding: 160px 0px;
    }
  }
</style>
