<template>
  <div class="footer">

      <div class="container">

        <div class="row">

          <div class="col-12 content">
            <div class="row">

              <div class="col-6">

                <router-link class="logo" to="/">
                  <component :is="universe().logo" classes="colored"></component>
                </router-link>

                <div class="tabs">
                  <router-link :to="'/'+$route.params.category+'/partnere'">Partnere</router-link>
                </div>

              </div>

              <div class="col-6 text-right">

                <p>Abonnér på {{universe().title}}</p>

                <div class="icons">
                  <a v-if="universe().socials.apple" :href="universe().socials.apple"><img src="@/assets/images/socials/apple.svg" alt="Apple Podcast" /></a>
                  <a v-if="universe().socials.spotify" :href="universe().socials.spotify"><img src="@/assets/images/socials/spotify.svg" alt="Spotify" /></a>
                  <a v-if="universe().socials.android" :href="universe().socials.android"><img src="@/assets/images/socials/android.svg" alt="Android Podcast" /></a>
                  <a v-if="universe().socials.podimo" :href="universe().socials.podimo"><img src="@/assets/images/socials/podimo.svg" alt="Podimo" /></a>
                </div>

              </div>

            </div>
          </div>

          <div class="col-12 legal">
            <div class="row">

              <div class="col-6 copyright">
                <p>Münster</p>
                <p>CVR: 38996142</p>
              </div>

              <div class="col-6 text-right links">
                <router-link :to="'/'+$route.params.category+'/privatlivspolitik'">Privatlivspolitik</router-link>
                <router-link :to="'/'+$route.params.category+'/cookiepolitik'">Cookiepolitik</router-link>
              </div>

            </div>
          </div>

        </div>

      </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LogoPenge from '@/components/svg/LogoPenge'
import LogoRejser from '@/components/svg/LogoRejser'

export default {
  name: 'FooterX',
  components: {
    LogoPenge,
    LogoRejser
  },
  computed: {
    ...mapGetters({
      universes: 'universes'
    })
  },
  methods: {
    universe() {
      return this.universes[this.$route.params.category] ?? false
    }
  }
}
</script>

<style scoped lang="scss">
.footer {
  display: block;
  padding: 80px 0px 60px 0px;
  background: var(--black);
  color: #FFFFFF;
  .content {
    p {
      font-size: 1rem;
    }
    .tabs {
      a {
        display: inline-block;
        color: #FFFFFF;
        margin: 20px 20px 0px 0px;
        transition: color 0.3s;
        &:hover {
          color: var(--grey);
        }
      }
    }
    .icons {
      img {
        width: 28px;
        height: auto;
        filter: brightness(0) invert(1);
        margin: 0px 0px 20px 20px;
      }
    }
  }
  .legal {
    margin-top: 60px;
    a, p {
      font-size: 0.8rem;
      display: inline-block;
      color: var(--grey);
      &[href] {
        margin-left: 10px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .copyright {
      p {
        margin: 0px 15px 0px 0px;
      }
    }
    .links {
      a {
        margin-left: 0px 0px 0px 20px;
      }
    }
  }
}
</style>