<template>
  <div id="app">

    <div v-if="$route.meta.simpleView">
      <router-view />
    </div>

    <div v-else class="global" :style="universe().colors()">
      <navigation />
      <router-view />
      <newsletter />
      <footer-x />
      <audio-player v-if="player.src" :id="player.id" :title="player.title" :sources="[player.src]" :autoplay="false" :html5="true" :loop="false"></audio-player>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Navigation from '@/components/Navigation'
import Newsletter from '@/components/Newsletter'
import FooterX from '@/components/Footer'
import AudioPlayer from '@/components/AudioPlayer'

export default {
  components: {
    Navigation,
    Newsletter,
    FooterX,
    AudioPlayer
  },
  computed: {
    ...mapGetters({
      player: 'player',
      universes: 'universes',
      episodes: 'episodes/all'
    }),
    getCategory: function() {
      return this.$route.params.category
    }
  },
  mounted() {
    this.fetchData(),
    this.$cookiebot.consentBanner({
      async: true,
      locale: 'da'
    })
  },
  watch: {
    getCategory: function () {
      this.fetchData()
    }
  },
  methods: {
    universe() {
      return this.universes[this.$route.params.category] ?? false
    },
    ...mapActions({
      fetchEpisodes: 'episodes/fetch'
    }),
    ...mapMutations([
      'setPlayerSrc'
    ]),
    async fetchData() {
      await this.fetchEpisodes()
      if (this.$route.params.category && !this.player.src) {
        this.setPlayerSrc({ src: this.episodes[0].playback_url, id: this.episodes[0].episode_id, title: this.episodes[0].title })
      }
    }
  },
  metaInfo() {
    return {
      title: this.universes[this.$route.params.category]?.meta.title,
      meta: [
        {name: "description", content: this.universes[this.$route.params.category]?.meta.description},
        {property: "og:title", content: this.universes[this.$route.params.category]?.meta.title},
        {property: "og:description", content: this.universes[this.$route.params.category]?.meta.description},
        {property: "og:image", content: this.universes[this.$route.params.category]?.meta.image},
      ]
    }
  }
}
</script>

<style lang="scss">
@import './assets/sass/app';

.global {
  padding-bottom: 100px;
  @media (max-width: 576px) {
    padding-bottom: 76px;
  }
}
</style>
