<template>
  <div class="index">
    <div class="navigation">
      <router-link class="logo" to="/">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="115"
          height="42"
          viewBox="0 0 115 42"
        >
          <path
            d="M2.04362 25.0356L2.91723 22.6966H7.30088L8.15889 25.0356H10.3273L6.30247 14.6238H4.02484L0 25.0356H2.04362ZM6.63007 20.8936H3.57244L5.10125 16.7679L6.63007 20.8936ZM13.6033 25.0356V13.8604H11.7001V25.0356H13.6033ZM18.845 25.263C19.7654 25.263 20.4674 24.9056 20.8886 24.4833L20.0306 23.0052C19.8434 23.2326 19.5002 23.395 19.157 23.395C18.5954 23.395 18.299 23.0377 18.299 22.3068V19.0094H20.3114V17.2552H18.2834V15.241H16.3802V17.2552H15.0542V19.0094H16.3646V22.3717C16.3646 24.2722 17.3318 25.263 18.845 25.263ZM29.0007 25.263C31.1691 25.263 32.9008 23.4113 32.9008 21.1535C32.9008 18.8795 31.1691 17.0278 29.0007 17.0278C26.8323 17.0278 25.1163 18.8795 25.1163 21.1535C25.1163 23.4113 26.8323 25.263 29.0007 25.263ZM29.0163 23.395C27.8619 23.395 27.0195 22.388 27.0195 21.1373C27.0195 19.8865 27.8619 18.8957 29.0163 18.8957C30.1551 18.8957 30.9819 19.8865 30.9819 21.1373C30.9819 22.388 30.1551 23.395 29.0163 23.395ZM36.5356 25.0356V20.6175C36.5356 19.5617 37.0972 18.8957 38.0176 18.8957C38.9068 18.8957 39.484 19.5617 39.484 20.6175V25.0356H41.3873V20.6175C41.3873 19.5617 41.9489 18.8957 42.8693 18.8957C43.7585 18.8957 44.3357 19.5617 44.3357 20.6175V25.0356H46.2389V20.3251C46.2389 18.246 45.1001 17.0278 43.3685 17.0278C42.1829 17.0278 41.4185 17.6937 40.9816 18.4571C40.5292 17.5475 39.6868 17.0278 38.5948 17.0278C37.7056 17.0278 36.988 17.4338 36.5356 18.0836V17.2552H34.6324V25.0356H36.5356Z"
            fill="black"
          />
          <path
            d="M66.2495 23.2094V25.0356H115V23.2094H66.2495Z"
            fill="black"
          />
          <path
            d="M47.8639 2.32044C51.8443 2.32044 55.0787 5.64777 55.143 9.77792L55.144 9.90332V26.5805L58.5127 29.0366L55.144 31.4927V32.0967C55.144 36.2428 51.9493 39.6116 47.9843 39.6785L47.8639 39.6796V42C52.9054 42 57.0304 37.9137 57.3523 32.7478L57.354 32.7141L62.3981 29.0366L57.3725 25.3725L57.3725 9.90332C57.3725 4.48864 53.2002 0.0887826 48.0211 0.00132688L47.8639 0V2.32044Z"
            fill="#A2B2CC"
          />
        </svg>
      </router-link>
    </div>

    <div class="section grey">
      <div class="container">
        <p class="title">Podcasts</p>

        <div class="row">
          <div
            v-for="(universe, index) in universes"
            :key="index"
            class="col-12 col-sm-6 col-lg-6 mb-4 mb-sm-0"
          >
            <router-link :class="'podcast ' + index" :to="'/' + index">
              <p class="title">{{ universe.title }}</p>

              <div class="icon" :style="universe.colors()">
                <component :is="universe.icon"></component>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="container">
        <p class="title">Hvad er Alt Om X</p>
        <p>
          Alt Om X er et podcast-netværk, der sætter strøm til internettets bagkatalog af ekspertviden. Vi finder de bedste eksperter inden for et emne og formidler deres guides til dig i lydformat, så du kan blive klogere på alt om nærmest hvad som helst på cyklen, i toget, mens du folder sokker, eller hvad du nu laver, når du lytter til dine podcasts.
        </p>

        <p class="information">info@altomx.dk</p>
      </div>
    </div>

    <div class="footer">
      <div class="container">
        <div class="row">
          <div class="col-12 content">
            <router-link class="logo" to="/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="115"
                height="42"
                viewBox="0 0 115 42"
              >
                <path
                  d="M2.04362 25.0356L2.91723 22.6966H7.30088L8.15889 25.0356H10.3273L6.30247 14.6238H4.02484L0 25.0356H2.04362ZM6.63007 20.8936H3.57244L5.10125 16.7679L6.63007 20.8936ZM13.6033 25.0356V13.8604H11.7001V25.0356H13.6033ZM18.845 25.263C19.7654 25.263 20.4674 24.9056 20.8886 24.4833L20.0306 23.0052C19.8434 23.2326 19.5002 23.395 19.157 23.395C18.5954 23.395 18.299 23.0377 18.299 22.3068V19.0094H20.3114V17.2552H18.2834V15.241H16.3802V17.2552H15.0542V19.0094H16.3646V22.3717C16.3646 24.2722 17.3318 25.263 18.845 25.263ZM29.0007 25.263C31.1691 25.263 32.9008 23.4113 32.9008 21.1535C32.9008 18.8795 31.1691 17.0278 29.0007 17.0278C26.8323 17.0278 25.1163 18.8795 25.1163 21.1535C25.1163 23.4113 26.8323 25.263 29.0007 25.263ZM29.0163 23.395C27.8619 23.395 27.0195 22.388 27.0195 21.1373C27.0195 19.8865 27.8619 18.8957 29.0163 18.8957C30.1551 18.8957 30.9819 19.8865 30.9819 21.1373C30.9819 22.388 30.1551 23.395 29.0163 23.395ZM36.5356 25.0356V20.6175C36.5356 19.5617 37.0972 18.8957 38.0176 18.8957C38.9068 18.8957 39.484 19.5617 39.484 20.6175V25.0356H41.3873V20.6175C41.3873 19.5617 41.9489 18.8957 42.8693 18.8957C43.7585 18.8957 44.3357 19.5617 44.3357 20.6175V25.0356H46.2389V20.3251C46.2389 18.246 45.1001 17.0278 43.3685 17.0278C42.1829 17.0278 41.4185 17.6937 40.9816 18.4571C40.5292 17.5475 39.6868 17.0278 38.5948 17.0278C37.7056 17.0278 36.988 17.4338 36.5356 18.0836V17.2552H34.6324V25.0356H36.5356Z"
                  fill="#FFFFFF"
                />
                <path
                  d="M66.2495 23.2094V25.0356H115V23.2094H66.2495Z"
                  fill="#FFFFFF"
                />
                <path
                  d="M47.8639 2.32044C51.8443 2.32044 55.0787 5.64777 55.143 9.77792L55.144 9.90332V26.5805L58.5127 29.0366L55.144 31.4927V32.0967C55.144 36.2428 51.9493 39.6116 47.9843 39.6785L47.8639 39.6796V42C52.9054 42 57.0304 37.9137 57.3523 32.7478L57.354 32.7141L62.3981 29.0366L57.3725 25.3725L57.3725 9.90332C57.3725 4.48864 53.2002 0.0887826 48.0211 0.00132688L47.8639 0V2.32044Z"
                  fill="#A2B2CC"
                />
              </svg>
            </router-link>
          </div>

          <div class="col-12 legal">
            <div class="row">
              <div class="col-12 copyright">
                <p>Münster</p>
                <p>CVR: 38996142</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import Coin from "@/components/svg/Coin";
  import Compass from "@/components/svg/Compass";

  export default {
    name: "Index",
    components: {
      Coin,
      Compass,
    },
    computed: {
      ...mapGetters({
        universes: "universes",
      }),
    },
  };
</script>

<style lang="scss" scoped>
  .index {
    .navigation {
      background: #ffffff;
      display: inline-block;
      width: 100%;
      padding: 0 80px;
      @media (max-width: 768px) {
        padding: 0px 20px;
      }
      .logo {
        height: 110px;
        display: -webkit-box;
        -webkit-box-align: center;
        svg {
          height: 50px;
          width: auto;
        }
      }
    }

    .section {
      padding: 160px 0;
      .title {
        display: block;
        font-size: 3.2rem;
        font-weight: 700;
        margin-bottom: 50px;
        word-wrap: break-word;
      }
      .information {
        font-size: 1.2rem;
        margin: 40px 0px 0px 0px;
        color: #a2b2cc;
        font-weight: 600;
      }
      &.grey {
        background: #f4f8ff;
      }
    }

    .podcast {
      display: inline-block;
      width: 100%;
      background: #ffffff;
      text-align: center;
      padding: 40px 40px 0 40px;
      .title {
        font-size: 1.8rem;
        color: #000000;
        margin-bottom: 40px;
      }
      .icon {
        svg {
          height: auto;
          width: 100%;
        }
      }
    }

    .footer {
      display: block;
      padding: 80px 0px 60px 0px;
      background: #242438;
      color: #ffffff;
      .logo {
        svg {
          height: 50px;
          width: auto;
        }
      }
      .legal {
        margin-top: 60px;
        a,
        p {
          font-size: 0.8rem;
          display: inline-block;
          color: #a2b2cc;
          &[href] {
            margin-left: 10px;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        .copyright {
          p {
            margin: 0px 15px 0px 0px;
          }
        }
      }
    }
  }
</style>
