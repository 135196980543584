<template>
  <div class="signed-up">

    <section class="top">
      <div class="container">

        <div class="row">

          <div class="col-12 col-md-6 title">
            <p class="title">Tak for din tilmelding</p>
          </div>

          <div class="col-12 col-md-5 offset-md-1 description">
            <p>{{universe().subscribedNotice}}</p>
            <router-link :to="'/'+$route.params.category">Tilbage til forsiden</router-link>
          </div>

          <div class="icon">
            <component :is="universe().icon"></component>
          </div>

        </div>

      </div>
    </section>

    <div v-if="episodes">
      <episodes :episodes="episodes" title="Seneste episoder" :limit="3" :button="false" :filters="false" />
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Episodes from '@/components/Episodes'
import Coin from '@/components/svg/Coin'
import Compass from '@/components/svg/Compass'

export default {
  name: 'SignedUp',
  components: {
    Episodes,
    Coin,
    Compass
  },
  computed: {
    ...mapGetters({
      episodes: 'episodes/all',
      universes: 'universes'
    })
  },
  methods: {
    universe() {
      return this.universes[this.$route.params.category] ?? false
    }
  }
}
</script>

<style lang="scss" scoped>
.signed-up {
  .top {
    position: relative;
    display: block;
    padding: 120px 0px;
    background: var(--darker);
    color: #FFFFFF;
    overflow: hidden;
    .title {
      z-index: 10;
      p {
        font-size: 3rem;
        font-weight: 700;
        line-height: 1;
        word-wrap: break-word;
        @media (max-width: 576px) {
          font-size: 2.6rem;
        }
      }
    }
    .description {
      z-index: 10;
      p {
        margin: 0px 0px 30px 0px;
      }
      a {
        display: block;
        margin-top: 15px;
        color: #FFFFFF;
        text-decoration: underline;
      }
    }
    .icon {
      position: absolute;
      bottom: 0;
      left: -5%;
      height: 80%;
      z-index: 1;
      opacity: 0.6;
    }
  }
}
</style>
