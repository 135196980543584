<template>
  <div class="mb-5">

    <div class="episode h-100">

      <div class="d-flex flex-column justify-content-between h-100">

        <div>
          <div class="title">
            <router-link :to="{ name: 'Episode', params: { id: id }}">{{ heading }}</router-link>
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <div class="tiny-info">
              <p>{{ format(published) }}</p>
            </div>

            <div class="tiny-info text-right">
              <p>{{ new Date(duration).toISOString().substr(11, 8) }}</p>
            </div>
          </div>

          <div class="description">
            <router-link :to="{ name: 'Episode', params: { id: id }}">
              {{ excerpt }}
            </router-link>
          </div>
        </div>

        <div class="d-flex justify-content-between align-items-end">

          <div class="mr-3">
            <component
              :is="clickableTags ? 'button' : 'div'"
              class="tag"
              v-for="(tag, index) in paginatedTags"
              :key="index"
              @click="clickableTags && $emit('tag-click', tag)">
              {{ tag }}
            </component>

            <p v-if="visibleTags < tags.length" class="show-all-tags" @click="visibleTags = tags.length">Se alle tags</p>

          </div>

          <div class="flex-shrink-0">
            <div class="play" @click="setPlayerSrc({ src: url, id: id, title: title })">
              <svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                <path class="triangle" d="m16,9.938147l0,28l22,-14l-22,-14z"/>
              </svg>
            </div>
          </div>

        </div>

      </div>

    </div>

  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'EpisodeItem',
  props: {
    id: { type: [String, Number], required: true },
    title: { type: String, default: '' },
    duration: { type: Number, required: true },
    url: { type: [String, Number], required: true },
    description: { type: String, default: '' },
    clickableTags: { type: Boolean, default: true },
    published: { type: String, default: '' },
    tags: { type: Array, default: () => [] }
  },
  methods: {
    ...mapMutations([
      'setPlayerSrc'
    ])
  },
  computed: {
    heading() {
      return (this.title.length < 50) ? this.title : this.title.substring(0, 46) + '...'
    },
    excerpt() {
      return (this.description.length < 140) ? this.description : this.description.substring(0, 136) + '...'
    },
    paginatedTags() {
      return this.tags.slice(0, this.visibleTags)
    }
  },
  data() {
    return {
      visibleTags: 1
    };
  }
}
</script>

<style lang="scss" scoped>
.episode {
  display: block;
  background: #FFFFFF;
  padding: 26px 32px;
  color: var(--black);
  p {
    margin: 0px;
  }
  .title {
    a {
      font-size: 1.4rem;
      font-weight: 600;
      color: var(--black);
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .tiny-info {
    margin-top: 10px;
    p {
      color: var(--grey);
      font-size: 0.8rem;
    }
  }
  .description {
    margin: 20px 0px;
    a {
      display: block;
      color: var(--black);
    }
  }
  .tag {
    border: 1px solid var(--lighter);
    background-color: transparent;
    margin: 0px 10px 10px 0px;
  }
  .show-all-tags {
    display: inline-block;
    font-size: 0.7rem;
    padding: 5px 0px;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .play {
    display: inline-block;
    height: 36px;
    width: 36px;
    padding: 8px;
    border-radius: 18px;
    background: var(--darker);
    transition: background 0.3s;
    &:hover {
      cursor: pointer;
    }
    svg {
      vertical-align: inherit;
      width: 100%;
      height: 100%;
      .triangle {
        fill: #FFFFFF;
      }
    }
  }
}
</style>