import router from '../../router'
import store from '../../store'
import axios from 'axios'
import _ from 'underscore'

export const actions = {
  async fetch({commit}) {
    let category = router.history.current.params.category
    let universes = store.getters.universes
    let speakerID = (universes[category]) ? universes[category].spreakerID : 4753105
    const episodes = await axios.get('https://api.spreaker.com/v2/shows/'+speakerID+'/episodes?export=episode_tags,episode_profile')
    commit('setEpisodes', episodes.data.response.items)
    commit('setTags', _.chain(episodes.data.response.items)
    .pluck('tags').flatten().uniq().value())
  },

  async fetchDetails({commit}, episodes) { // eslint-disable-line no-empty-pattern
    const data = []

    episodes.map(async (episode) => {
      const e = await axios.get('https://api.spreaker.com/v2/episodes/'+episode.episode_id)
      commit('setDetails', { id: e.episode_id, description: e.description })
    })

    return data
  }
}