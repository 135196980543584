import { actions } from './actions.js'
import { getters } from './getters.js'
import { mutations } from './mutations.js'

export const episodes = {
  namespaced: true,
  state: {
    episodes: [],
    tags: []
  },
  mutations,
  actions,
  getters
}