import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueCookieBot from "@ambitiondev/vue-cookiebot-plugin";
import VueMeta from "vue-meta";

Vue.config.productionTip = false;

Vue.use(VueCookieBot, {
  cookieBotID: "c3dacea5-7f30-44f2-8e1f-a25178c8fb28",
});

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

Vue.mixin({
  methods: {
    format(x) {
      let months = [
        "Januar",
        "Februar",
        "Marts",
        "April",
        "Maj",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "December",
      ];
      x = x.split(" ")[0].split("-");
      let y = parseInt(x[0]),
        m = parseInt(x[1]) - 1,
        d = parseInt(x[2]);
      return d + ". " + months[m] + " " + y;
    },
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
