<template>
  <div class="episode episode-show">
    <div class="container">
      <div class="row">
        <div v-if="episode" class="col-md-10 col-lg-8 mx-auto">
          <div class="body">

            <p class="small published">
              {{ format(episode.published_at) }}
            </p>

            <h1>
              {{ episode.title }}
            </h1>

            <div class="d-flex align-items-center mb-5 mt-4">

              <div class="play d-flex align-items-center justify-content-center mr-3" @click="setPlayerSrc({ src: episode.playback_url, id: episode.episode_id, title: episode.title })">
                <svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                  <path class="triangle" d="m16,9.938147l0,28l22,-14l-22,-14z"/>
                </svg>
              </div>

              <div class="fw-600 fs-22">
                {{ new Date(episode.duration).toISOString().substr(11, 8) }}
              </div>

            </div>

            <p class="description" v-html="linkifiedDescription" />

            <div class="tag" v-for="(tag, index) in episode.tags" :key="index">
              {{ tag }}
            </div>

          </div>
        </div>
      </div>
    </div>

    <episodes :episodes="episodes" :limit="3" :button="false" :filters="false" title="Seneste episoder" />

  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Episodes from '@/components/Episodes'
import linkify from 'linkifyjs/string'

export default {
  name: 'Episode',
  components: {
    Episodes
  },
  computed: {
    ...mapGetters({
      findEpisode: 'episodes/episode',
      episodes: 'episodes/all'
    }),

    episode() {
      return this.findEpisode(parseInt(this.$route.params.id))
    },

    linkifiedDescription() {
      return linkify(this.episode.description, {
        attributes: {
          style: 'color: var(--darker);'
        }
      });
    }
  },
  methods: {
    ...mapMutations([
      'setPlayerSrc'
    ])
  }
}
</script>

<style lang="scss" scoped>
.episode-show {
  .body {
    padding: 100px 0;
    .published {
      color: var(--grey);
    }
    .play {
      width: 80px;
      height: 80px;
      border: 1px solid var(--lighter);
      border-radius: 50%;
      cursor: pointer;
      svg {
        width: 32px;
      }
    }
    .tag {
      border: 1px solid var(--lighter);
      color: var(--darker);
      margin: 10px 10px 0px 0px;
    }
    .description {
      white-space: pre-line;
      word-break: break-word;
    }
    @media (max-width: 576px) {
      h1 {
        font-size: 2.2rem;
        word-wrap: break-word;
      }
      .play {
        width: 60px;
        height: 60px;
        svg {
          width: 24px;
        }
      }
    }
  }
}
</style>