<template>
  <div class="player">

    <div class="content row align-items-center">

      <div class="col-12 col-sm-8 col-md-10">

        <div class="play" @click="togglePlayback">
          <img v-if="playing" src="@/assets/images/pause.svg" />
          <img v-else-if="duration == 0" src="@/assets/images/loading.svg" />
          <img v-else src="@/assets/images/play.svg" />
        </div>

        <div class="reset" @click="stop">
          <img src="@/assets/images/reset.svg" />
        </div>

        <div class="title">
          <router-link :to="{ name: 'Episode', params: { id: id }}">{{ title }}</router-link>
        </div>

      </div>

      <div class="d-none d-sm-block col-4 col-md-2">
        <div class="duration">
          <a v-if="duration == 0">Indlæser...</a>
          <a v-else>{{ new Date(duration * 1000).toISOString().substr(11, 8) }}</a>
        </div>
      </div>

    </div>

    <progress :value="progress" @click="progressClick" min="0" max="1"></progress>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VueHowler from 'vue-howler'

export default {
  mixins: [VueHowler],
  props: {
    id: { type: [String, Number], required: true },
    title: { type: String, default: '' }
  },
  computed: {
    ...mapGetters({
      player: 'player'
    })
  },
  watch: {
    player: {
      handler() {
        const self = this
        setTimeout(function() { self.play() }, 100)
      },
      deep: true
    }
  },
  methods: {
    progressClick: function(e) {

      let x = e.pageX - e.target.offsetLeft;
      let clickedValue = x * e.target.max / e.target.offsetWidth;

      this.setProgress(clickedValue);

    }
  }
}
</script>

<style lang="scss" scoped>
.player {
  z-index: 999;
  height: 100px;
  width: 100%;
  background: #FFFFFF;
  border-top: 1px solid #E8E8E8;
  position: fixed;
  left: 0;
  bottom: 0;
  .content {
    height: calc(95% - 5px);
    padding: 0px 80px;
    .play {
      display: inline-block;
      height: 46px;
      width: 46px;
      border-radius: 23px;
      padding: 10px;
      margin-right: 10px;
      background: var(--darker);
      transition: background 0.3s;
      &:hover {
        cursor: pointer;
      }
      img {
        height: 100%;
        width: 100%;
      }
    }
    .reset {
      display: inline-block;
      height: 34px;
      width: 34px;
      margin-right: 40px;
      &:hover {
        cursor: pointer;
      }
      img {
        height: 100%;
        width: 100%;
      }
    }
    .title {
      display: inline-block;
      vertical-align: middle;
      a {
        display: block;
        font-size: 1.2rem;
        color: var(--black);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 980px;
        @media (max-width: 1500px) {
          width: 720px;
        }
        @media (max-width: 1200px) {
          width: 540px;
        }
        @media (max-width: 992px) {
          width: 360px;
        }
        @media (max-width: 768px) {
          width: 200px;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .duration {
      width: 100%;
      text-align: right;
    }
  }
  progress {
    vertical-align: top;
    border-radius: 0px; 
    height: calc(5% + 5px);
    width: 100%;
    &:hover {
      cursor: pointer;
    }
  }
  progress::-webkit-progress-bar {
    background: var(--lightest);
  }
  progress::-webkit-progress-value {
    background: var(--darker);
  }
  @media (max-width: 576px) {
    height: 76px;
    .content {
      padding: 0px 20px;
      .play {
        height: 40px;
        width: 40px;
        border-radius: 20px;
        padding: 8px;
        margin-right: 6px;
      }
      .reset {
        height: 30px;
        width: 30px;
        margin-right: 20px;
      }
    }
  }
}
</style>